<template>
  <V-card class="mx-auto my-md-16" max-width="600">
    <v-card-title>
      <div class="d-md-flex justify-space-between" style="width: 100%">
        <div>
          Loja Teste AA
          <br />
          <small class="text--secondary"> Rua Teste A, 123, Centro </small>
          <br />
          <small class="text--secondary"> Mantena - MG </small>
        </div>
        <div>
          <b>Pedido #888</b>
        </div>
      </div>
    </v-card-title>
    <v-card-text>
      <v-divider class="mt-4 mb-4" />
      <template v-for="(row, index) in sale.sale_products">
        <v-row class="mt-2" :key="index">
          <v-col cols="1">
            <b>{{ $format.decimal(row.quantity, 0) }}</b>
          </v-col>
          <v-col cols="7">
            {{ row.product.name }}
            <br />
            <small class="text--secondary">
              R$ {{ $format.decimal(row.price) }}</small
            >
          </v-col>
          <v-col cols="4" class="text-right">
            R$
            {{
              $format.decimal(row.price * row.quantity - row.product_discount)
            }}
            <template v-if="row.product_discount > 0">
              <br />
              <small class="error--text"
                >- R${{ $format.decimal(row.product_discount) }} Desc.</small
              >
            </template>
          </v-col>
        </v-row>
      </template>
      <v-divider class="mt-8 mb-4" />
      <v-row>
        <v-col class="text-right">
          <span class="text-secondary mr-4">Subtotal: </span>
          <h3 class="d-inline">{{ $format.decimal(sale.product_total) }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right">
          <span class="text-secondary mr-4">Desconto: </span>
          <h3 class="d-inline">{{ $format.decimal(sale.general_discount) }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right">
          <span class="text-secondary mr-4">Total: </span>
          <h2 class="d-inline">{{ $format.decimal(sale.net_total) }}</h2>
        </v-col>
      </v-row>
      <v-divider class="mt-4 mb-4" />
      <v-row>
        <v-col class="text-center py-10">
          <h1 class="mb-8">R$ {{ $format.decimal(sale.net_total) }}</h1>
          <v-btn
            class="text-capitalize"
            block
            color="secondary"
            @click="createPayment()"
          >
            Pagar
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </V-card>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    sale: {},
  }),

  created() {
    this.loadOrder();
  },

  methods: {
    loadOrder() {
      axios
        .get("/api/customer/sale/sale/" + this.$route.params.id)
        .then((response) => {
          this.sale = response.data.sale;
        });
    },

    createPayment() {
      axios.post("/api/customer/payment/mercadopago-preference", { sale_id : this.sale.id }).then((response) => {
        console.log(response)
        //window.location.href = response.data.preference.init_point;
      });
    },
  },
};
</script>

<style>
.v-main {
  background-color: #f5f5f5;
}
</style>